var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "rounded-card", attrs: { flat: "", outlined: "" } },
    [
      _vm._t("title", [
        _vm.title
          ? _c("v-card-title", {
              domProps: { textContent: _vm._s(_vm.$t(_vm.title)) }
            })
          : _vm._e()
      ]),
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { dense: "", flat: "" } },
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              index !== 0
                ? _c("v-divider", {
                    key: index,
                    staticClass: "my-0",
                    attrs: { inset: true }
                  })
                : _vm._e(),
              _c(
                "v-list-item",
                { key: item.title },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "4",
                                md: "4",
                                lg: "4",
                                xl: "4"
                              }
                            },
                            [
                              _c("v-list-item-subtitle", {
                                staticClass: "font-weight-bold overline",
                                domProps: {
                                  innerHTML: _vm._s(_vm.$t(item.key))
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "8",
                                md: "8",
                                lg: "8",
                                xl: "8"
                              }
                            },
                            [
                              item.phone && item.value
                                ? _c(
                                    "div",
                                    [
                                      _c("vue-tel-input-vuetify", {
                                        attrs: {
                                          outline: true,
                                          rounded: true,
                                          disabled: "",
                                          dense: true,
                                          disabledFetchingCountry: true,
                                          label: ""
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("v-list-item-title", {
                                        staticClass: "font-weight-medium",
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
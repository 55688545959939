<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <user-information-card :items="profile.items">
      <template v-slot:title>
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar size="150">
              <app-avatar :url="getFileUrl(user.avatar)" :alternative="user.initials" size="xlarge" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold h1 ml-2" v-text="user.name" />
              <v-list-item-subtitle class="font-weight-bold h4 ml-2" v-text="user.jobTitle" />
              <v-list-item-group v-for="(item, index) in user.links" :key="index" class="mt-5">
                 <v-hover v-slot:default="{ hover }">
                  <v-btn icon :href="item.link" target="_blank" :class="hover ? 'hover-link' : ''">
                    <v-icon>{{`la-${item.platform}`}}</v-icon>
                  </v-btn>
                 </v-hover>
              </v-list-item-group>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </user-information-card>
    <user-information-card class="mt-10" :title="contactInfo.title" :items="contactInfo.items" />
    <user-information-card class="mt-10" :title="workInfo.title" :items="workInfo.items" />
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import UserInformationCard from 'components/users/InformationCard';

export default {
  name: 'UserPublicProfile',
  components: {
    UserInformationCard
  },
  data() {
    return {
      title: {
        text: this.$tc('models.user.entity', 2),
        icon: 'mdi-account-group'
      }
    };
  },
  computed: {
    ...mapGetters(['user']),
    breadcrumbs() {
      return [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$tc('models.user.entity', 2),
        to: {name: 'Users'},
        exact: true
      },
      {
        text: this.user.id,
        to: {name: 'UserPublicProfile'},
        exact: true
      }];
    },
    profile() {
      return {
        items: [{
          key: 'models.user.attributes.bio',
          value: this.user.bio
        }, {
          key: 'models.user.attributes.birthday',
          value: this.user.birthdate ? this.user.birthdate : ''
        }, {
          key: 'models.user.attributes.address',
          value: this.user.address
        }]
      };
    },
    contactInfo() {
      return {
        title: 'views.users.contactInfo',
        items: [{
          key: 'models.user.attributes.email',
          value: this.user.email
        }, {
          key: 'models.user.attributes.phoneNumber',
          value: this.user.phoneNumber,
          phone: true
        }, {
          key: 'models.user.attributes.emergencyNumber',
          value: this.user.emergencyNumber,
          phone: true
        }]
      };
    },
    workInfo() {
      return {
        title: 'views.users.workInfo',
        items: [{
          key: 'models.user.attributes.id',
          value: this.user.employeeId
        }, {
          key: 'models.user.attributes.jobType',
          value: this.user.jobType ? this.$t(`models.user.types.${this.user.jobType}`) : ''
        }, {
          key: 'models.user.attributes.startDate',
          value: this.user.startDate ? this.user.startDate : ''
        }, {
          key: 'models.user.attributes.role',
          value: this.user.jobType ? this.$t(`models.user.roles.${this.user.role}`) : ''
        }]
      };
    }
  },
  methods: mapActions(['getUser']),
  created() {
    this.getUser({uuid: this.$route.params.uuid});
  },
  watch: {
    '$route.params.uuid': function(uuid) {
      this.getUser({uuid: uuid});
    }
  }
};
</script>
<style scoped lang="scss">
.hover-link {
  .la-facebook {
    color: #1973E7;
  }
  .la-linkedin {
    color: #3378B6;
  }
  .la-twitter {
    color: #47A2F2;
  }
  .la-github {
    color: #000;
  }
}
</style>

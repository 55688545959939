var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c("user-information-card", {
        attrs: { items: _vm.profile.items },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "v-list",
                  { attrs: { "two-line": "" } },
                  [
                    _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-avatar",
                          { attrs: { size: "150" } },
                          [
                            _c("app-avatar", {
                              attrs: {
                                url: _vm.getFileUrl(_vm.user.avatar),
                                alternative: _vm.user.initials,
                                size: "xlarge"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              staticClass: "font-weight-bold h1 ml-2",
                              domProps: { textContent: _vm._s(_vm.user.name) }
                            }),
                            _c("v-list-item-subtitle", {
                              staticClass: "font-weight-bold h4 ml-2",
                              domProps: {
                                textContent: _vm._s(_vm.user.jobTitle)
                              }
                            }),
                            _vm._l(_vm.user.links, function(item, index) {
                              return _c(
                                "v-list-item-group",
                                { key: index, staticClass: "mt-5" },
                                [
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var hover = ref.hover
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  class: hover
                                                    ? "hover-link"
                                                    : "",
                                                  attrs: {
                                                    icon: "",
                                                    href: item.link,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        "la-" + item.platform
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("user-information-card", {
        staticClass: "mt-10",
        attrs: { title: _vm.contactInfo.title, items: _vm.contactInfo.items }
      }),
      _c("user-information-card", {
        staticClass: "mt-10",
        attrs: { title: _vm.workInfo.title, items: _vm.workInfo.items }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }